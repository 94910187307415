<script setup>
import { ref } from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import { hashString } from '@/utils.js';
import NavBar from '@/components/NavBar';
import Wizard from '@/components/Wizard';
import WizardStep from '@/components/WizardStep';
import WizardStepContent from '@/components/WizardStepContent';
import WizardButtons from '@/components/WizardButtons';
import WizardNextButton from '@/components/WizardNextButton';
import WizardPreviousButton from '@/components/WizardPreviousButton';
import Code from '@/components/CodeInput';
import { Form } from 'vee-validate';
import { PASSWORD_RULES as passwordRules } from '@/constants/common';
import FormInput from '@/components/FormInput';
import { Auth } from '@aws-amplify/auth';
import validate from '@/validate';
import notify from '@/notify';
import { simpleLocale, t } from '@/i18n';
import Icon from '@/components/Icon';

function isPasswordStrong (password) {
    return password && validate.isPasswordStrong(password);
}

// we initialize these fields values here to be able to access it in submitUserInfo
const firstName = ref('');
const lastName = ref('');
const email = ref('');
const ongoingSignup = ref(false);
const ongoingVerifyEmail = ref(false);

function saveName (values, nextStep) {
    firstName.value = values.firstName;
    lastName.value = values.lastName;
    nextStep();
}

async function submitUserInfo (values, nextStep) {
    ongoingSignup.value = true;
    email.value = values.email;

    if (firstName.value && lastName.value && isPasswordStrong(values.password)) {
        try {
            await Auth.signUp({
                username: values.email,
                password: values.password,
                attributes: {
                    email: values.email,
                    given_name: firstName.value,
                    family_name: lastName.value,
                },
                clientMetadata: {
                    lang: simpleLocale,
                },
                autoSignIn: {
                    enabled: true, // make the user authenticated after entering the email code
                },
            });

            datadogRum.addAction('signup_step_started', {
                step: 'email_verification',
                hashedUserEmail: hashString(email.value),
            });

            nextStep();
        } catch (error) {
            switch (error.name) {
                case 'UsernameExistsException':
                    notify.error(t('err-signup-username-exists-exception'));
                    datadogRum.addAction('signup_email_already_exists');
                    break;
                default:
                    notify.error(t('err-unknown'));
            }
        }
    } else {
        notify.error(t('err-unknown'));
    }

    ongoingSignup.value = false;
}

async function submitVerifyEmail (values, nextStep) {
    ongoingVerifyEmail.value = true;

    if (email.value) {
        try {
            await Auth.confirmSignUp(email.value, values.emailVerificationCode);

            datadogRum.addAction('signup_step_started', {
                step: 'success',
                hashedUserEmail: hashString(email.value),
            });
            nextStep();
        } catch (error) {
            switch (error.name) {
                case 'CodeMismatchException': // currently we don't show a different error message
                case 'ExpiredCodeException':
                    notify.error(t('err-signup-code-verification-failure'));
                    break;
                default:
                    notify.error(t('err-unknown'));
            }
        }
    } else {
        notify.error(t('err-unknown'));
    }

    ongoingVerifyEmail.value = false;
}
</script>

<template>
    <div class='min-h-full bg-grey-50 flex items-center'>
        <NavBar />
        <div class='w-full pt-12 pb-24 px-8'>
            <Wizard v-slot='{ previousStep, nextStep }' class='max-w-7xl mx-8 overflow-hidden'>
                <WizardStep
                    title='onboarding-personal.steps.details.title'
                    desc='onboarding-personal.steps.details.desc'
                    icon='User'
                    header-title='onboarding-personal.steps.details.header-title'
                    header-description='onboarding-personal.steps.details.header-desc'
                >
                    <Form
                        tag='div'
                        v-slot='{ values, handleSubmit }'
                    >
                        <WizardStepContent>
                            <FormInput
                                autocomplete='given-name'
                                :label='$t("lbl-firstname")'
                                name='firstName'
                                rules='required'
                                mode='eager'
                                edit
                                class='mb-4'
                            />
                            <FormInput
                                autocomplete='family-name'
                                :label='$t("lbl-lastname")'
                                name='lastName'
                                rules='required'
                                mode='eager'
                                edit
                            />
                        </WizardStepContent>
                        <WizardButtons>
                            <WizardNextButton @click='handleSubmit($event, () => { saveName(values, nextStep)})' id='step-name' />
                        </WizardButtons>
                    </Form>
                </WizardStep>
                <WizardStep
                    title='onboarding-personal.steps.login.title'
                    desc='onboarding-personal.steps.login.desc'
                    icon='Key'
                    :header-title='$t("onboarding-personal.steps.login.header-title", {"firstName": firstName})'
                    header-description='onboarding-personal.steps.login.header-desc'
                >
                    <Form
                        tag='div'
                        v-slot='{ values, handleSubmit }'
                    >
                        <WizardStepContent>
                            <FormInput
                                autocomplete='email'
                                :label='$t("lbl-email")'
                                name='email'
                                rules='email|required'
                                ref='emailInput'
                                mode='eager'
                                edit
                                class='mb-4'
                            />
                            <FormInput
                                autocomplete='new-password'
                                :label='$t("lbl-password")'
                                type='password'
                                name='password'
                                rules='required|password'
                                ref='password'
                                mode='eager'
                                edit
                            />
                            <div class='mt-3'>
                                <ul class='list-none pl-0 text-blue-400 text-sm'>
                                    <li class='list-none' v-for='rule in passwordRules' :key='rule.label'>
                                        <span
                                            v-if='values.password && values.password.length > 0 && validate.checkPasswordRule(rule.regex, values.password)'
                                            class='flex items-center text-green-300'
                                        >
                                            <Icon name='Check' class='mr-1 w-5' />
                                            {{ $t(rule.label) }}
                                        </span>
                                        <span v-else class='text-grey-300 flex items-center'>
                                            <Icon name='ArrowRight' class='mr-1 w-5' />
                                            {{ $t(rule.label) }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </WizardStepContent>
                        <WizardButtons>
                            <WizardPreviousButton @click='previousStep' />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { submitUserInfo(values, nextStep)})'
                                :loading='ongoingSignup'
                                id='step-email-password'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStep>
                <WizardStep
                    title='onboarding-personal.steps.verify-email.title'
                    desc='onboarding-personal.steps.verify-email.desc'
                    icon='Inbox'
                    header-title='onboarding-personal.steps.verify-email.header-title'
                    :header-description='$t("onboarding-personal.steps.verify-email.header-desc", {"email": email})'
                >
                    <Form
                        tag='div'
                        ref='verifyEmailForm'
                        v-slot='{ values, handleSubmit }'
                    >
                        <WizardStepContent>
                            <Code name='emailVerificationCode' rules='required' :label='$t("lbl-email-verification-code")' />
                        </WizardStepContent>
                        <WizardButtons>
                            <WizardNextButton
                                @click='handleSubmit($event, () => { submitVerifyEmail(values, nextStep)})'
                                :loading='ongoingVerifyEmail'
                                id='step-email-verification'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStep>
                <WizardStep
                    title='onboarding-personal.steps.done.title'
                    desc='onboarding-personal.steps.done.desc'
                    icon='Sparkles'
                    header-title='onboarding-personal.steps.done.header-title'
                >
                    <WizardStepContent>
                        <h2 class='text-4xl font-regular m-0 py-3 mb-3'>
                            {{ $t('onboarding-personal.steps.done.text1') }}
                        </h2>
                        <p class='mb-6'>
                            {{ $t('onboarding-personal.steps.done.text2') }}
                        </p>
                    </WizardStepContent>
                    <WizardButtons>
                        <WizardNextButton
                            @click='() => $router.push("/")'
                            label='onboarding-personal.steps.done.next'
                            id='step-redirect-mycodabox'
                        />
                    </WizardButtons>
                </WizardStep>
            </Wizard>
        </div>
    </div>
</template>

<style scoped>
strong {
    @apply font-bold;
}

p {
    @apply leading-8 text-xl text-grey-600 font-light;
}
</style>
